/* hide popover window, if clicked on background */

(() => {
  const popOverBg = document.querySelector('.popover-hide');

  popOverBg && popOverBg.addEventListener('click', () => {
    popOverBg.style.display = 'none';
  })
})();

/* menu slider */

(() => {
  const navigation = document.querySelector('.main-navigation');
  const hambMenu = document.querySelector('.hamb-menu');
  const htmlBody = document.querySelector('body');
  
  const menuBg = document.createElement('div');
  menuBg.classList.add('visible-bg', 'hide-menubg');
  document.body.appendChild(menuBg);

  const menuOpener = () => {
    navigation.classList.toggle('smooth-slide');
    menuBg.classList.toggle('hide-menubg');
    htmlBody.style.overflow === 'hidden' ? (htmlBody.style.overflow = '') : (htmlBody.style.overflow = 'hidden');    
  }

  menuBg.addEventListener('click', menuOpener);
  hambMenu.addEventListener('click', menuOpener);

  navigation.addEventListener('click', () => {
    htmlBody.style.overflow = '';
  })
})();

/* navbar slide up-down */

(() => {
  let currHeight = 0;
  const navBar = document.querySelector('.main-navbar');
  window.addEventListener('scroll', () => {
    if (currHeight > 75 && window.scrollY > currHeight) {
      navBar.classList.add('translate-up');
    } else if (currHeight <= 75){
      navBar.classList.remove('white-bg', 'translate-up');      
    } else {
      navBar.classList.remove('translate-up');
      navBar.classList.add('white-bg');
    }
    currHeight = window.scrollY;
  })
})();

/* if popup window exceeds window height its scrollable */

(() => {
  const popOverBg = document.querySelector('.popover-bg');

  if (popOverBg && popOverBg.clientHeight > window.innerHeight) {
    popOverBg.style.position = 'absolute'
  }
})();

(() => {
  const accordionOpener = document.querySelector('.section-opener');
  const accordionContent = document.querySelector('.accordion-section')
  
  if (accordionOpener) {
    accordionOpener.addEventListener('click', () => {
      accordionContent.classList.toggle('closed-section');
    })
  }
})();

/* image wrapper height */

(() => {
  const imgSizer = () => {
    const image = document.querySelector('.img-silhouette');
    const imageWrapper = document.querySelector('.img-wrapper');
    if (image) {
      imageWrapper.style.setProperty('--imgHeight', `${image.clientHeight / 2}px`);
    }
  }

  window.addEventListener('load', imgSizer);
  window.addEventListener('change', imgSizer);
})();

(() => {
  const userInfo = document.querySelector('.user-info');

  if (userInfo) {
    const userClose = document.querySelector('.user-close');

    userClose.addEventListener('click', () => {
      userInfo.classList.toggle('translate-left');
    });
  }
})();

/* further posts grid */

(() => {
  const furtherPostsHolder = document.querySelector('.further-posts');

  if (furtherPostsHolder) {
    const furtherPosts = furtherPostsHolder.querySelector('ul');
    const singlePosts = furtherPostsHolder.querySelectorAll('li');
    furtherPosts.style.setProperty('--blogSize', singlePosts.length);

    let currentPosition = Number(furtherPosts.style.getPropertyValue('--currPos'));
    const postSlider = document.querySelector('.further-post-slider');
		
		postSlider.firstElementChild.addEventListener('click', () => {
			if (currentPosition > 0) {
				furtherPosts.style.setProperty('--currPos', currentPosition - 1);
				currentPosition = Number(furtherPosts.style.getPropertyValue('--currPos'));
			}
		})
		
		postSlider.lastElementChild.addEventListener('click', () => {
			if (currentPosition < furtherPosts.childElementCount - 3) {
				furtherPosts.style.setProperty('--currPos', currentPosition + 1);
				currentPosition = Number(furtherPosts.style.getPropertyValue('--currPos'));
			}
		})
  }
})();

/* further post image height */

(() => {
  const imgHeight = document.querySelectorAll('.single-post-img-holder');
  let setHeight = Number.MAX_SAFE_INTEGER;
  if (imgHeight.length > 0) {
    imgHeight.forEach(img => {
      if (img.clientHeight < setHeight) {
        setHeight = img.clientHeight;
      }
    })
    imgHeight.forEach(img => {
      img.style.setProperty('--holderHeight', `${setHeight}px`);
    })
  }
})();

/* go back to prevoius page */

(() => {
  const closeButtons = document.querySelectorAll('.popover-close');

  if (closeButtons.length > 0) {
    closeButtons.forEach(btn => {
      btn.addEventListener('click', () => {
        window.location.href = window.location.origin;
      })
    })
  }
})();

/* close popover window */

(() => {
  const newsLetter = document.querySelector('#newsletter-subscription');

  if (newsLetter) {
    const closeButton = newsLetter.querySelector('.message-close');

    if (closeButton) {
      closeButton.addEventListener('click', () => {
        newsLetter.classList.add('hide-menubg');
        document.body.style.overflow = '';
      });
    }
  }
})();

/* popup newsletter */

(() => {
  const pageAudios = document.querySelectorAll('audio');
  const newsLetter = document.querySelector('#newsletter-subscription');

  pageAudios.forEach(audio => {
    if (audio.src.endsWith('ingyenes.mp3')) {
      audio.addEventListener('timeupdate', () => {
        if (audio.currentTime >= 25 && audio.currentTime < 26) {
          newsLetter.classList.remove('hide-menubg');
          document.body.style.overflow = 'hidden';
        }
      })
    }
  })
})();

/* get the top margin from image */

(() => {
  const highlightedGrid = document.querySelectorAll('.highlighted-grid');

  if (highlightedGrid.length > 0) {
    highlightedGrid.forEach(grid => {
      const imageHolder = grid.querySelector('.highlighted-third');
      const textHolder = grid.querySelector('.highlighted-text');
      window.addEventListener('load', () => {
        textHolder.style.setProperty('--imgMargin', `${imageHolder.clientHeight}px`);
      })
    })
  }
})();

/* registration slide - currently turned OFF */
(() => {
  const checkboxSlider = document.getElementById('user_new_subscriber');
  const checkboxHelper = document.getElementById('subscription_text');
  const subscriptionPrice = document.getElementById('subscription_price');
  checkboxSlider && checkboxSlider.addEventListener('change', () => {
    if (checkboxSlider.checked) {
      checkboxHelper.innerText = 'Korlátozott előfizetés, amely 1 hétig, illetve az első 3 hanganyag meghallgatásáig tart.';
      subscriptionPrice.innerText = '6.350 Ft';
    } else {
      checkboxHelper.innerText = 'Teljeskörű előfizetés, melynek hossza 1 hónap és az összes segédanyag letölthető vele';
      subscriptionPrice.innerText = '25.400 Ft';
    }
  })
});

/* checkout popup */

(() => {
  const regForm = document.querySelector('.registration-form');

  const openSubscribe = () => {
    const newsSubscribe = document.querySelector('#newsletter-subscription');
    const newsContainer = newsSubscribe.querySelector('.message-bg');
    const newsTitle = newsSubscribe.querySelector('h3')
    newsTitle.textContent = 'Mikor fogod elkezdeni?';
    
    const regButton = newsSubscribe.querySelector('a.hrp-button');
    const oldText = newsSubscribe.querySelector('p');

    if (regButton) {
      oldText.remove();
      const newsText = document.createElement('p');
      newsText.innerHTML = 'Várunk vissza, amikor legközelebb megfájdul a fejed.<br>Ha addig is szeretnél hasznos tippeket kapni a fejfájásmentes élethez iratkozz fel hírlevelünkre!';
      newsContainer.insertBefore(newsText, regButton);
      regButton.remove();
    }
    newsSubscribe.classList.remove('hide-menubg');
    document.body.style.overflow = 'hidden';
  }
  
  if (regForm) {
    document.addEventListener('mouseleave', openSubscribe, false );
  } else {
    document.removeEventListener('mouseleave', openSubscribe, false);
  }
});